import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AddShoppingCart, InfoOutlined, Warning } from '@material-ui/icons'
import { inject } from 'mobx-react'
import React from 'react'
import { IStores } from '../../Stores'
import Moment from 'moment'
import ReactPaginate from 'react-paginate'
import {
  searchClaims,
  RemoveClaimFromGlobal,
  AddClaimToGlobal,
  getQuoteTotals,
} from 'src/services/ClaimService'
import { formatDate, formatCurrency } from 'src/utils/Formatter'
import { ClaimSearchRequest } from 'src/viewModels/ClaimSearchRequest'
import { ClaimSummaryResult } from 'src/viewModels/ClaimSummaryResult'
import { pagedList } from 'src/viewModels/pagedList'
import ResponsiveAppBar from 'src/common/ResponsiveAppBar'
import { IQuoteDTO } from 'src/viewModels/quotes/QuoteDTO'
import { BundleQuoteSummary } from 'src/viewModels/BundleQuoteSummary'
import PaymentHistoryPanel from '../globalInvoice/PaymentHistoryPanel'
import { QuoteStatus } from 'src/utils/QuoteStatus'
import AppealNameConstants from 'src/constants/AppealNameConstants'
import { AppealType } from 'src/utils/AppealType'
import ReferralContactModal from 'src/patients/EpisodesOfCare/ReferralContactModal'
import QuoteTypeNameConstants from 'src/constants/QuoteTypeNameConstants'
import { QuoteType } from 'src/utils/QuoteType'
import moment from 'moment'

interface IQuoteBillsDialogProps {
  close?: (actionTaken: boolean) => void
  isOpen?: boolean
  quote?: IQuoteDTO
  setClaimsModalLoadFunction?: (
    parentfunction?: (episodeOfCareId: string) => void
  ) => void
  showButtons?: boolean
  openReferralContactModal?: () => void
  isReferralContactModalOpen?: boolean
  billOutTitle?: string
}
class BundleClaimsModal extends React.Component<IQuoteBillsDialogProps> {
  public quoteStatus = {
    0: 'Needs Quote',
    2: 'Needs Reconcilation',
    3: 'Reconciled',
    5: 'Imported',
    6: 'Draft',
    7: 'Quoted',
    8: 'Pending Contract',
    9: 'Pending Care Coordinator',
    10: 'Pending Finance',
    12: 'Dismissed',
  }

  private closeDialog = () => {
    this.setState({
      gridData: new pagedList().items as ClaimSummaryResult[],
      page: 1,
      pageCount: 0,
      firstRecordIndex: 0,
      lastRecordIndex: 0,
      totalItems: 0,
      pageSize: 5,
      gridLoading: false,
      quoteInfo: undefined as unknown as BundleQuoteSummary,
      actionTaken: false,
    })
    this.props.close!(this.state.actionTaken)
  }
  public render() {
    const { isOpen, quote, isReferralContactModalOpen } = this.props
    const quoteId = quote ? quote.id : ''
    const patientName = quote?.patientName
    const dob = Moment(quote?.dateOfBirth).format('MM/DD/YYYY')

    return (
      <Dialog
        key={quoteId}
        fullWidth={true}
        maxWidth="lg"
        open={isOpen!}
        onClose={this.closeDialog!}
      >
        <DialogTitle style={{ padding: 0 }}>
          <ResponsiveAppBar
            title={
              this.props.billOutTitle
                ? `Bundle Claims (${this.props.billOutTitle}) - ${
                    patientName ?? 'N/A'
                  } [${dob}]`
                : `Bundle Claims - ${patientName ?? 'N/A'} [${dob}]`
            }
            pageIcon={<AddShoppingCart />}
            isModal={true}
          />
        </DialogTitle>
        <DialogContent>
          {this.state.gridLoading && (
            <div
              style={{
                position: 'fixed',
                display: 'flex',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1200',
              }}
            >
              <CircularProgress size={100} />
            </div>
          )}
          {isReferralContactModalOpen && (
            <ReferralContactModal selectedReferralId={this.props.quote?.referralId} />
          )}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingTop: 12, paddingBottom: 5 }}
          >
            <Grid container item xs={12} spacing={2} wrap="nowrap">
              <Grid item style={{ whiteSpace: 'nowrap' }}>
                <strong>Type:</strong>&nbsp;
                {quote?.type != undefined ? QuoteTypeNameConstants[quote?.type] : 'N/A'}
              </Grid>
              <Grid item style={{ whiteSpace: 'nowrap' }}>
                {quote?.type == QuoteType.Surgical ||
                quote?.type == QuoteType.Injection ? (
                  <>
                    <strong>Surgery Date: </strong>
                    <span>
                      {quote.surgeryDate ? moment(quote.surgeryDate).format('L') : 'N/A'}
                    </span>
                  </>
                ) : (
                  <>
                    <strong>ToC Date: </strong>
                    <span>
                      {quote && quote.takeoverOfCareDate
                        ? moment(quote.takeoverOfCareDate).format('L')
                        : 'N/A'}
                    </span>
                  </>
                )}
              </Grid>
              <Grid item style={{ whiteSpace: 'nowrap' }}>
                <strong># of Days:</strong>&nbsp;
                {quote?.bundleDurationDays != undefined
                  ? quote?.bundleDurationDays
                  : 'N/A'}
              </Grid>
              <Grid item style={{ whiteSpace: 'nowrap' }}>
                <strong>End Date:</strong>&nbsp;
                {quote?.endDate != undefined ? moment(quote.endDate).format('L') : 'N/A'}
              </Grid>
              <Grid item style={{ whiteSpace: 'nowrap' }}>
                <strong>Quote Status:</strong>&nbsp;
                {this.quoteStatus[this.state.quoteInfo?.currentQuoteStatus]}
              </Grid>
              {quote?.globalInvoiceId === undefined && (
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Warning style={{ color: 'orange' }} />
                  <strong>Not Invoiced</strong>
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={this.props.openReferralContactModal}
                >
                  Referral Contact
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} wrap="nowrap">
              <Grid item>
                <strong>PT Visits Quoted:</strong>&nbsp;
                {this.state.quoteInfo?.quotedPTCount ?? 'N/A'}
              </Grid>
              <Grid item>
                <strong>PT Visits Reconciled:</strong>&nbsp;
                {this.state.quoteInfo?.reconciledPTCount ?? 'N/A'}
              </Grid>
              <Grid item>
                <strong>Received PT Claims:</strong>&nbsp;
                {this.state.quoteInfo?.receivedPTClaimCount ?? 'N/A'}
              </Grid>
              <Grid item>
                <strong>Bundled PT Claims:</strong>&nbsp;
                {this.state.quoteInfo?.receivedPTClaimCountInBundle ?? 'N/A'}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>EOC-Group#</TableCell>
                  <TableCell>Claim Status</TableCell>
                  <TableCell>Billed Out Status</TableCell>
                  <TableCell style={{ width: '555px' }}>Provider Location</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell align="center">DOS</TableCell>
                  <TableCell align="center">Billed Charges</TableCell>
                  <TableCell>Contract Amt</TableCell>
                  <TableCell>Component</TableCell>
                  <TableCell>Reconciled</TableCell>
                  <TableCell>In Global</TableCell>
                  {this.props.showButtons ?? true ? <TableCell></TableCell> : <></>}
                </TableRow>
              </TableHead>
              <TableBody>{this.mapGridData()}</TableBody>
            </Table>
            <div className="pagination-row">
              {this.paginationInfo()}
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={this.handlePageChange}
                pageCount={this.state.pageCount}
                containerClassName={'pagination'}
                activeClassName={'active'}
                //@ts-ignore
                renderOnZeroPageCount={null}
              />
            </div>
          </Grid>
          <Grid container xs={12} justifyContent="flex-start" spacing={1}>
            <Grid container item xs={4} direction="column" justifyContent="space-between">
              <Grid container item justifyContent="flex-start">
                <Grid container item direction="row">
                  <Grid item xs={12} container justifyContent="center">
                    <Typography variant="subtitle1">
                      <strong>QUOTE TOTALS:</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item direction="row">
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography variant="caption">QUOTED TOTAL:</Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Typography variant="caption">
                      {this.state.quoteInfo?.quotedTotal == undefined
                        ? 'N/A'
                        : formatCurrency(this.state.quoteInfo.quotedTotal.toString())}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <Grid container item direction="row" justifyContent="space-between">
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography variant="caption">QUOTED PROFIT:</Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Typography variant="caption">
                      {this.state.quoteInfo?.quotedTotal == undefined ||
                      this.state.quoteInfo?.totalQuotedCost == undefined
                        ? 'N/A'
                        : formatCurrency(
                            (
                              this.state.quoteInfo.quotedTotal -
                              this.state.quoteInfo.totalQuotedCost
                            ).toString()
                          ) +
                          ' (' +
                          this.state.quoteInfo.quotedProfitPercentage +
                          '%)'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <Grid container item direction="row" justifyContent="space-between">
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography variant="caption">RECONCILED TOTAL:</Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Typography variant="caption">
                      {this.state.quoteInfo?.reconciledTotal == undefined ||
                      !this.state.quoteInfo?.showReconciled
                        ? 'N/A'
                        : formatCurrency(
                            this.state.quoteInfo?.reconciledTotal.toString()
                          )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
                <Grid container item direction="row" justifyContent="space-between">
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography variant="caption">RECONCILED PROFIT:</Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Typography variant="caption">
                      {this.state.quoteInfo?.reconciledTotal == undefined ||
                      this.state.quoteInfo?.totalReconciledCost == undefined ||
                      !this.state.quoteInfo?.showReconciled ||
                      quote == undefined
                        ? 'N/A'
                        : formatCurrency(
                            (
                              this.state.quoteInfo.reconciledTotal -
                              this.state.quoteInfo.totalReconciledCost
                            ).toString()
                          ) +
                          ' (' +
                          this.state.quoteInfo.reconciledProfitPercentage +
                          '%)'}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="column"
                justifyContent="center"
                style={{ height: '50%' }}
              >
                <Grid container item direction="row" justifyContent="space-between">
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Typography variant="subtitle1" style={{ color: 'green' }}>
                      <strong>EOC PROFIT MARGIN:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Typography variant="subtitle1" style={{ color: 'green' }}>
                      <strong>{this.state.quoteInfo?.profitMarginDisplay}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} direction="row">
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="subtitle1">
                    <strong>BUNDLED TOTALS:</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL CHARGES:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(
                      this.state.quoteInfo?.billedChargesInBundle.toString()
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL CONTRACT:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(
                      this.state.quoteInfo?.contractTotalInBundle.toString()
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL INVOICED:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {this.state.quoteInfo?.totalInvoicedDisplay}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL PAID:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(this.state.quoteInfo?.totalPaidInBundle.toString())}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    OSX OWES (OUTSTANDING):
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    ({formatCurrency(this.state.quoteInfo?.osxOwesInBundle.toString())})
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL RECEIVED:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(
                      this.state.quoteInfo?.totalReceivedInBundle.toString()
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    OSX OWED (OUTSTANDING):
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    ({formatCurrency(this.state.quoteInfo?.osxOwedInBundle.toString())})
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>PROFIT TO DATE:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>{this.state.quoteInfo?.profitToDateInBundleDisplay}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>ANTICIPATED PROFIT:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>
                      {formatCurrency(
                        this.state.quoteInfo?.anticipatedProfitInBundle.toString()
                      )}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} direction="row">
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={12} container justifyContent="center">
                  <Typography variant="subtitle1">
                    <strong>EOC TOTALS:</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL CHARGES:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(this.state.quoteInfo?.billedChargesTotal.toString())}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL CONTRACT:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(this.state.quoteInfo?.contractTotal.toString())}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL INVOICED/BILLED OUT:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {this.state.quoteInfo?.totalInvoicedAndBilledOutDisplay}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL PAID:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(this.state.quoteInfo?.totalPaid.toString())}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    OSX OWES (OUTSTANDING):
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    ({formatCurrency(this.state.quoteInfo?.osxOwes.toString())})
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption">TOTAL RECEIVED:</Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption">
                    {formatCurrency(this.state.quoteInfo?.totalReceived.toString())}
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    OSX OWED (OUTSTANDING):
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="caption" style={{ color: 'red' }}>
                    ({formatCurrency(this.state.quoteInfo?.osxOwed.toString())})
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>PROFIT TO DATE:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>{this.state.quoteInfo?.profitToDateDisplay}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12} direction="row" justifyContent="space-between">
                <Grid item xs={6} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>ANTICIPATED PROFIT:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                  <Typography variant="subtitle1" style={{ color: 'green' }}>
                    <strong>
                      {formatCurrency(this.state.quoteInfo?.anticipatedProfit.toString())}
                    </strong>
                  </Typography>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingTop: '5px' }}>
            {this.state.quoteInfo ? (
              <PaymentHistoryPanel payments={this.state.quoteInfo.payments} />
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} data-cy="close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  public state = {
    gridData: new pagedList().items as ClaimSummaryResult[],
    page: 1,
    pageCount: 0,
    firstRecordIndex: 0,
    lastRecordIndex: 0,
    totalItems: 0,
    pageSize: 5,
    gridLoading: true,
    quoteInfo: undefined as unknown as BundleQuoteSummary,
    actionTaken: false,
  }

  private mapGridData = () => {
    if (this.isGridDataEmpty()) {
      return (
        <TableRow key={1}>
          <TableCell colSpan={12} align="center">
            No records found
          </TableCell>
        </TableRow>
      )
    } else {
      return this.state.gridData.map((claim, index) => (
        <TableRow key={index}>
          <TableCell style={{ whiteSpace: 'nowrap', width: '1px' }}>
            {claim.eocGroupNumber}&nbsp;&nbsp;
            {claim.appealType != undefined ? (
              <Tooltip title={`Appeal Type: ${AppealNameConstants[claim.appealType]}`}>
                <InfoOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <></>
            )}
          </TableCell>
          <TableCell>{claim.status}</TableCell>
          <TableCell>{claim.billOutStatus ?? 'N/A'}</TableCell>
          <TableCell>{claim.providerLocation}</TableCell>
          <TableCell>{claim.claimType}</TableCell>
          <TableCell align="right">{formatDate(claim.dateOfService)}</TableCell>
          <TableCell align="right">{formatCurrency(claim.totalClaimCharges)}</TableCell>
          <TableCell
            align="right"
            style={{
              color:
                claim.appealType != undefined &&
                claim.appealType === AppealType.OverpaidProvider
                  ? 'red'
                  : 'black',
            }}
          >
            {claim.totalContractAmountDisplay != undefined
              ? claim.totalContractAmountDisplay
              : ''}
          </TableCell>
          <TableCell>{claim.component}</TableCell>
          <TableCell>{claim.percentageOfLineItemsReconciled}%</TableCell>
          <TableCell>{claim.inGlobal}</TableCell>
          {this.props.showButtons ?? true ? (
            <TableCell align="center">{this.getButton(claim)}</TableCell>
          ) : (
            <></>
          )}
        </TableRow>
      ))
    }
  }

  private getClaimData = async (episodeOfCareId: string) => {
    this.setState({ gridLoading: true })
    const { page, pageSize } = this.state
    const requestData = new ClaimSearchRequest()
    requestData.page = page
    requestData.pageSize = pageSize
    requestData.episodeOfCareId = episodeOfCareId
    await searchClaims(requestData).then(async (response: any) => {
      this.setState({
        gridData: response.items,
        pageCount: response.totalPages,
        totalItems: response.totalItems,
        gridLoading: false,
      })

      if (response.totalItems > 0) {
        this.setPaginationOffsetData()
      }

      await getQuoteTotals(episodeOfCareId).then((response: BundleQuoteSummary) => {
        this.setState({ quoteInfo: response })
      })
    })
  }

  private getButton(claim: ClaimSummaryResult) {
    var buttonText = ''
    var action = () => {}
    var isDisabled = false

    if (claim.inGlobal == 'Yes') {
      buttonText = 'Remove'
      action = async () => {
        this.setState({ actionTaken: true })
        await RemoveClaimFromGlobal(claim.cms1500Id!)
        this.getClaimData(this.props.quote?.episodeOfCareId!)
      }
    } else {
      buttonText = 'Add'
      action = async () => {
        this.setState({ actionTaken: true })
        await AddClaimToGlobal(claim.cms1500Id!)
        this.getClaimData(this.props.quote?.episodeOfCareId!)
      }
    }
    if (
      claim.percentageOfLineItemsReconciled! > 0 ||
      claim.isBilledOut ||
      claim.status == 'Draft' ||
      (this.state.quoteInfo?.currentQuoteStatus &&
        this.state.quoteInfo?.currentQuoteStatus == QuoteStatus.Dismissed)
    ) {
      isDisabled = true
    }
    return (
      <Button
        onClick={action}
        variant="contained"
        color="primary"
        style={{ color: 'white', width: '92px' }}
        disabled={isDisabled}
      >
        {buttonText}
      </Button>
    )
  }

  public componentDidMount() {
    this.props.setClaimsModalLoadFunction!(this.getClaimData)
  }

  public componentWillUnmount() {
    this.setState({ page: 1 })
  }

  private paginationInfo = () => {
    if (!this.isGridDataEmpty()) {
      return (
        <div className="pagination">
          <Typography variant="caption" gutterBottom>
            Showing {this.state.firstRecordIndex} to&nbsp;
            {this.state.lastRecordIndex} of {this.state.totalItems} entries
          </Typography>
        </div>
      )
    } else {
      return <></>
    }
  }
  private isGridDataEmpty = () => {
    return this.state.totalItems == 0
  }
  private setPaginationOffsetData = () => {
    const { page, totalItems, pageSize } = this.state
    const firstRecordIndex = (page - 1) * pageSize + 1
    const lastRecordIndex = page * pageSize < totalItems ? page * pageSize : totalItems
    this.setState({
      firstRecordIndex: firstRecordIndex,
      lastRecordIndex: lastRecordIndex,
      totalItems: totalItems,
    })
  }

  private handlePageChange = (event: any) => {
    const page = event.selected
    this.setState({ page: page + 1 }, () =>
      this.getClaimData(this.props.quote?.episodeOfCareId!)
    )
  }
}

const InjectedQuoteBillsDialog = inject<
  IStores,
  IQuoteBillsDialogProps,
  Partial<IQuoteBillsDialogProps>,
  any
>((stores: IStores) => ({
  close: stores.quotes.closeBillsDialog,
  isOpen: stores.quotes.isBillsDialogOpen,
  quote: stores.quotes.selectedQuote,
  setClaimsModalLoadFunction: stores.quotes.setClaimsModalLoad,
  billOutTitle: stores.quotes.billoutBundleClaimsTitle,
  patientHeader: stores.patients.headerDetails,
  openReferralContactModal: stores.patientEdit.openReferralContactModal,
  isReferralContactModalOpen: stores.patientEdit.isReferralContactModalOpen,
}))(BundleClaimsModal)

export default InjectedQuoteBillsDialog
